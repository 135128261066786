import QSComingSoon from "../assets/QS_coming_soon.png"

const ComingSoon = () => {
    return(
            <section className="
                qs-home-hero-bg 
                d-flex 
                flex-column 
                justify-content-between 
                align-items-center
                ">
                <img src={QSComingSoon} alt="" className="img-fluid py-5 my-5 w-50"/>
                <h1>NEW WEBSITE Coming Soon!</h1>
                <h4 className="text-center w-50">READY TO DRINK AND READY TO POUR SYSTEMS, PRODUCTS, INGREDIENTS AND SERVICES for venues and events.  </h4>
                <p>310-981-3388 | Admin@QuickServeCocktails.com</p>
            </section>
    )
}

export default ComingSoon